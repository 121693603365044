export default function arrowScroll() {
    let partnersContainer = document.querySelectorAll(".column__photo");
    let scrollActive = document.querySelectorAll(".sides");

    partnersContainer.forEach(partners => {
        scrollActive.forEach(scroll => {
            if (partners.childElementCount > 2 && partners.id == scroll.id) {
                scroll.classList.add("scroll--active");
            }
        });
    });
}