export default function searchControl() {
    if (
        document.getElementById("search") &&
        document.querySelector(".btn-search") &&
        document.querySelector(".btn-close")
    ) {
        let inputSearch = document.getElementById("search");
        let btnSearch = document.querySelector(".btn-search");
        let btnClose = document.querySelector(".btn-close");

        if (inputSearch.value.length > 0) {
            btnSearch.style.display = "none";
            btnClose.style.display = "inline-block";
        }

        btnClose.addEventListener("click", () => {
            inputSearch.value = "";
            btnSearch.style.display = "inline-block";
            btnClose.style.display = "none";
        });
    }
}
