import { Swiper, Navigation, Pagination, Autoplay } from "swiper/dist/js/swiper.esm.js";

Swiper.use([Navigation, Pagination, Autoplay]);

export default () => {
  let swiper = new Swiper('.gallery-modal-slider', {
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
  });

  let contact = new Swiper('.contact__slider__container', {
    slidesPerView: 2,
    spaceBetween: 5,
    speed: 2000,
    autoplay: {
      delay: 2000,
    },
    pagination: {
      el: '.contact__slider .swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
    navigation: {
      nextEl: '.arrows--next',
      prevEl: '.arrows--prev',
    },
    breakpoints: {
      800: {
        slidesPerView: 1,
      },
    },
  });

  let filter = new Swiper(".filter__container", {
    slidesPerView: "auto",
    watchOverflow: true,
    spaceBetween: 8,
    loop: true,
    navigation: {
      nextEl: ".arrows--next",
      prevEl: ".arrows--prev",
    }
  });

  let cases = new Swiper(".cases-slider", {
    slidesPerView: 2,
    spaceBetween: 21,
    watchOverflow: true,
    loop: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      800: {
        slidesPerView: 1,
      },
    },
  });

  let homeBanner = new Swiper(".home__banner__container", {
    slidesPerView: 1,
    watchOverflow: true,
    pagination: {
      el: ".home__banner .swiper-pagination",
      type: "bullets",
      clickable: true,
    },
    navigation: {
      nextEl: ".home__banner .arrows--next",
      prevEl: ".home__banner .arrows--prev",
    },
  });
  
  let team = new Swiper(".team__container", {
    slidesPerView: 4,
    watchOverflow: true,
    spaceBetween: 10,
    navigation: {
      nextEl: ".home__team .arrows--next",
      prevEl: ".home__team .arrows--prev",
    },
    breakpoints: {
      1199: {
        slidesPerView: 2,
      },
      600: {
        slidesPerView: 1,
      },
    },
  });

  let years = new Swiper(".years__container", {
    slidesPerView: 1,
    width: 70,
    spaceBetween: 10,
    allowTouchMove: false,
    navigation: {
      nextEl: ".home__years .arrows--next",
      prevEl: ".home__years .arrows--prev",
    },
    breakpoints: {
      600: {
        spaceBetween: 10,
      },
      1199: {
        spaceBetween: 80,
      },
      1920: {
        spaceBetween: 130,
      }
    },
  });

  let descriptionsYears = new Swiper(".years__description__container", {
    slidesPerView: 1,
    allowTouchMove: false,
    navigation: {
      nextEl: ".home__years .arrows--next",
      prevEl: ".home__years .arrows--prev",
    },
  });

  let history = new Swiper(".history__slider", {
    slidesPerView: 3,
    navigation: {
      nextEl: ".history-button-next",
      prevEl: ".history-button-prev",
    },
    breakpoints: {
      900: {
        slidesPerView: 1,
      },
      1199: {
        slidesPerView: 2,
      }
    }
  })
};
